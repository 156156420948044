import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import { SEOProperties } from "../../shared/interfaces/graphql.interface";

interface SEOProps {
  seo: SEOProperties;
}

const SEO: React.FC<SEOProps> = ({ seo }) => {
  return (
    <GatsbySeo
      title={seo?.seo_title}
      description={seo?.meta_description}
      nofollow={seo?.no_follow}
      noindex={seo?.no_index}
      openGraph={{
        title: seo?.facebook_title,
        description: seo?.facebook_description,
        images: [
          {
            url: seo?.facebook_image?.source_url,
            alt: seo?.facebook_image?.alt_text,
            height: seo?.facebook_image?.media_details.height,
            width: seo?.facebook_image?.media_details.width,
          },
        ],
      }}
    />
  );
};

export default SEO;
