import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { EzDivisor, RespContainer } from "../common";
import { Colors, Devices } from "../variables";

export const HeaderContainer = styled(RespContainer)`
  align-items: flex-start;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 1080px !important;

    align-items: center;
  }
`;

export const Logo = styled("img")`
  width: 100px;
  height: 100px;
  object-fit: cover;

  margin-top: 4rem;
  margin-bottom: 3.375rem;
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 8rem;
    margin-bottom: 1.125rem;
  }
`;

export const ProjectTitleDivisor = styled(EzDivisor)`
  width: 120px;
`;

export const ProjectTitle = styled("h1")`
  margin-top: 1.625rem;
  font-size: 1.875rem;
  font-weight: 500;
  margin-bottom: 1.25rem;
  color: ${Colors.ezBlack};
  align-self: flex-start;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    align-self: center;
    font-size: 4rem;
    font-weight: 600;
    margin-top: 3.75rem;
    margin-bottom: 2.5625rem;
  }
`;

export const CoverImageDesktop = styled(GatsbyImage)`
  display: none !important;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: initial !important;
    width: 80%;
    margin-top: 30px;
    margin-bottom: 75px;
  }
`;

export const CoverImageMobile = styled(GatsbyImage)`
  width: 100%;
  object-fit: contain;
  margin-top: 2.125rem;
  margin-bottom: 0.875rem;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none !important;
  }
`;

export const GrayBG = styled("div")`
  background: ${Colors.gray8};
`;

export const AboutContainer = styled(RespContainer)`
  align-items: flex-start;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 1080px !important;
  }
`;

export const ContentTitle = styled("h2")`
  width: 100%;
  font-size: 24px;
  margin-top: 27px;
  font-weight: 600;
  margin-bottom: 21px;
  color: ${Colors.ezBlack};

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 1.875rem;

    margin-top: 73px;
    font-size: 60px;
  }
`;

export const ContentTitleDivisor = styled(EzDivisor)`
  margin-bottom: 32px;
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: unset;
  }
`;

export const Content = styled("p")`
  color: ${Colors.solidGray};
  font-size: 0.9375rem;
  line-height: 175%;
  margin-top: 2rem;
  margin-bottom: 1.25rem;

  > p {
    padding-bottom: 10px;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    line-height: 180%;
    margin-top: 25px;
    margin-bottom: 90px;

    > p {
      padding-bottom: 7px;
    }
  }
`;

export const GrayBgWrapper = styled("div")`
  background-color: ${Colors.gray7};
`;
