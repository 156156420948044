import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled, { css } from "styled-components";
import {
  AppButton,
  AppButtonAnchor,
  AppButtonLink,
  DotsBackground,
  EzDivisor,
} from "../common";
import { Colors, Devices } from "../variables";

export const Container = styled("div")`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 3.375rem;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    flex-direction: row;
    margin-bottom: 7rem;
  }
`;

export const ImageWrapper = styled("div")`
  flex: 0 0 50%;
`;

export const Image = styled(GatsbyImage)`
  display: none !important;

  img {
    object-fit: initial !important;
    height: auto;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: block !important;
    margin-right: 3.5rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    margin-right: 5rem;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    margin-right: 9rem;
  }
`;

export const ImageReversed = styled(GatsbyImage)`
  display: none !important;

  img {
    object-fit: initial !important;
    height: auto;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: block !important;
    margin-left: 3.5rem;
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    margin-left: 5rem;
  }

  /* >= 1800px; */
  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    margin-left: 9rem;
  }
`;

export const Dots = styled(DotsBackground)`
  position: absolute;
  bottom: -40px;
  left: -40px;
  z-index: -1;
  opacity: 0.5;

  display: none;
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: block;
  }
  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    bottom: -60px;
    left: -60px;
  }
`;

export const DotsReversed = styled(Dots)`
  bottom: -40px;
  right: -40px;
  left: unset;

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    bottom: -60px;
    right: -60px;
    left: unset;
  }
`;

export const Content = styled("div")`
  flex: 0 0 50%;
`;

export const Title = styled("h2")`
  color: ${Colors.ezBlack};
  line-height: 4rem;
  font-weight: 600;
  padding: 0;

  font-size: 28px;
  margin-top: 24px;
  margin-bottom: 4px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin: 0 0 2.625rem 0;
    font-size: 3.75rem;
  }
`;

export const Divisor = styled(EzDivisor)`
  margin-bottom: 2rem;

  &::after {
    background-color: ${Colors.sharpBlue};
  }
`;

export const ImageMobileWrapper = styled("div")`
  position: relative;
  display: flex;
  justify-content: center;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none;
  }
`;
export const ImageMobile = styled(GatsbyImage)`
  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    display: none;
  }
`;

export const Subtitle = styled("h3")`
  margin: 0;
  padding: 2.125rem 0 0 0;
  color: ${Colors.ezBlack};
  font-size: 1.75rem;
  font-weight: 600;
`;

export const LabelWrapper = styled("div")<{ noMargins?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => (props.noMargins ? "unset" : "0rem")};
  margin-bottom: 1;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 0.5rem;
    margin-top: unset;
    width: 100%;
  }
`;

export const Label = styled("span")<{ small?: boolean }>`
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  background: ${Colors.gray3_07};
  border-radius: 8px;
  margin-bottom: 0.875rem;
  &:not(:last-child) {
    margin-right: 10px;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    ${({ small }) =>
      small
        ? `font-size: 11px;
           padding: 6px 14px;
           margin-top: 51px;`
        : ``}
  }
`;

export const Description = styled("div")`
  color: ${Colors.ezGray};
  margin: 30px 0 0.75rem 0;
  font-size: 0.9375rem;
  line-height: 178%;
  font-weight: 400;
  padding: 0;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin: 0.875rem 0 0.75rem 0;
    line-height: 1.75rem;
  }
`;

export const Footer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReadMore = styled(Link)`
  display: flex;
  color: ${Colors.ezBlack};
  font-size: 1.125rem;
  font-weight: 600;

  > span:first-child {
    border-bottom: 1px solid transparent;
  }

  &:focus {
    > span:first-child {
      border-bottom: 1px solid ${Colors.sharpBlue};
    }
  }

  &:focus,
  &:hover {
    outline: none;
    color: ${Colors.sharpBlue};
    text-decoration: none;
  }
`;

export const ReadMoreText = styled("span")``;

export const Button = styled(AppButtonLink)`
  width: 100%;
  max-width: 500px;
  font-size: 1rem;
  font-weight: 600;

  @media all and (min-width: ${Devices.bootstrapMobileBreakpoint}) {
    font-size: 1.25rem;
    padding: 1.125rem 0;
  }

  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    padding: 1.125rem 0;
  }

  @media all and (min-width: ${Devices.laptopBreakpoint}) {
    padding: 1.125rem 0;
  }
`;

export const ProjectButtonsWrapper = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;

  margin-top: 24px;

  > a:first-child {
    margin-bottom: 20px;
  }

  /* 768px, */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    flex-direction: row;

    > a:first-child {
      margin-bottom: 0;
    }
  }

  /* 1200px, */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-top: 0;
  }
`;

const ProjectButtonPattern = css`
  padding: 14px 0;
  width: 100%;
  max-width: 340px;

  /* 768px, */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    max-width: 280px;
  }

  /* 1200px, */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    max-width: 240px;
  }

  /* 1440px */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    max-width: 280px;
  }

  &:hover,
  &:focus {
    outline: none;
  }
`;

export const CaseStudyButton = styled(AppButtonLink)`
  ${ProjectButtonPattern}

  /* 768px, */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    margin-bottom: unset;
    margin-right: 1.25rem;
  }

  /* 1200px, */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-right: 1.25rem;
  }
`;

export const WebsiteButton = styled(AppButtonAnchor)`
  ${ProjectButtonPattern}
`;
