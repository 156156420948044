import React from "react";
import { getImage, IGatsbyImageData } from "gatsby-plugin-image";

import { Swiper, SwiperSlide } from "swiper/react";

import { EzDivisor, RespContainer } from "../styles/common";
import SectionEzDivisorConsultation from "../components/ReUsables/SectionEzDivisorConsultation";
import SectionEzDivisorNewsLetter from "../components/ReUsables/SectionEzDivisorNewsLetter";
import Testimonials from "../components/ReUsables/Testimonials";
import SectionLatestBlogPosts from "../components/ReUsables/SectionLatestBlogPosts";
import * as StylingSectionWithImage from "../styles/Reusables/StylingSectionWithImage";
import * as StylingProjectsContent from "../styles/ProjectsPage/StylingProjectsContent";
import * as StylingProjectsBody from "../styles/ProjectsPage/StylingProjectsBody";
import { useWindowSize } from "../components/CustomHooks/useWindowSize";
import SEO from "../components/ReUsables/SEO";
import ServicesWeOffer from "../components/ReUsables/ServicesWeOffer";

import {
  GlobalOptions,
  LabelsType,
  LastThreePostsType,
  ProjectType,
  SEOProperties,
  ServicesWeOfferType,
  TestimonialsType,
} from "../shared/interfaces/graphql.interface";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./projectsSliderStyles.scss";

// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { FontelloIcon } from "../styles/CommonComponents";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, A11y]);

interface PageDataProps {
  pageContext: { data: any };
}

interface PageContext extends ProjectType {
  testimonials: TestimonialsType;
  globalOptions: GlobalOptions;
  lastThreePost: LastThreePostsType;
  labels: LabelsType;
  services_we_offer: ServicesWeOfferType;
}

const Projects: React.FC<PageDataProps> = ({ pageContext }) => {
  let pageContextData: PageContext = pageContext.data;

  let labelsList = pageContextData.labels.nodes.filter((label) =>
    pageContextData.label.includes(parseInt(label.wordpress_id))
  );

  // const {
  //   before_image,
  //   after_images,
  // } = pageContextData.acf.challenges.before_after_images[0];

  let { width } = useWindowSize();
  return (
    <>
      <SEO seo={pageContextData?.acf?.seo} />
      <StylingProjectsContent.HeaderContainer>
        {/* <StylingProjectsContent.Logo
          src={pageContextData?.acf?.project_logo?.source_url}
          alt={pageContextData?.acf?.project_logo?.alt_text}
        /> */}

        <StylingProjectsContent.ProjectTitle
          dangerouslySetInnerHTML={{ __html: pageContextData.title }}
        />

        <StylingProjectsContent.ProjectTitleDivisor alwaysBlue />

        <StylingProjectsContent.CoverImageDesktop
          image={
            getImage(pageContextData?.acf?.image?.localFile) as IGatsbyImageData
          }
          alt={pageContextData?.acf?.image?.alt_text}
        />

        <StylingProjectsContent.CoverImageMobile
          image={
            getImage(pageContextData?.acf?.image?.localFile) as IGatsbyImageData
          }
          alt={pageContextData?.acf?.image?.alt_text}
        />
      </StylingProjectsContent.HeaderContainer>

      <StylingProjectsContent.GrayBG>
        <StylingProjectsContent.AboutContainer>
          <StylingProjectsContent.ContentTitle>
            About The Project
          </StylingProjectsContent.ContentTitle>
          <StylingProjectsContent.ContentTitleDivisor
            alwaysBlue
            alwaysSmall
            fullWidth
          />

          <StylingSectionWithImage.LabelWrapper noMargins>
            {labelsList?.length > 0 &&
              labelsList?.map(
                (item, idx) =>
                  item && (
                    <StylingSectionWithImage.Label
                      key={`label${idx}`}
                      small={true}
                    >
                      {item.name}{" "}
                    </StylingSectionWithImage.Label>
                  )
              )}
          </StylingSectionWithImage.LabelWrapper>

          <StylingProjectsContent.Content
            dangerouslySetInnerHTML={{ __html: pageContextData.content }}
          />
        </StylingProjectsContent.AboutContainer>
      </StylingProjectsContent.GrayBG>

      <SectionEzDivisorConsultation
        section={pageContextData.globalOptions.ez_divisor_consultation}
        phone={pageContextData.globalOptions.company_information.phone}
      />

      <RespContainer>
        <StylingProjectsBody.Container>
          {pageContextData.acf?.challenges?.show_before_after && (
            <StylingProjectsBody.ComparingImagesWrapper>
              <div className="ez-wrapper-container">
                <div className="ez-swiper-wrapper">
                  <button className="prev">
                    <FontelloIcon icon={"left-open"} />
                  </button>
                  <button className="next">
                    <FontelloIcon icon={"right-open"} />
                  </button>
                  <div className="ez-swiper">
                    <Swiper
                      // navigation={true}
                      slidesPerView={1}
                      navigation={{
                        nextEl: ".next",
                        prevEl: ".prev",
                      }}
                      breakpoints={{
                        0: {
                          slidesPerView: 1.35,
                        },
                        1200: {
                          slidesPerView: 1,
                          slidesPerGroup: 1,
                        },
                      }}
                    >
                      {pageContextData.acf?.challenges?.before_after_images.map(
                        (item, idx) => (
                          <SwiperSlide key={`befAft${idx}`}>
                            <>
                              <StylingProjectsBody.BeforeImageSection>
                                <StylingProjectsBody.ComparingImageTitle>
                                  Before
                                </StylingProjectsBody.ComparingImageTitle>

                                <StylingProjectsBody.BeforeImageWrapper>
                                  <StylingProjectsBody.BeforeImage
                                    src={`/images/before-pattern.png`}
                                    alt={"before-pattern"}
                                  />

                                  <StylingProjectsBody.BeforeImageContent1
                                    image={
                                      getImage(
                                        item?.before_image?.localFile
                                      ) as IGatsbyImageData
                                    }
                                    alt={item?.before_image?.alt_text}
                                  />
                                </StylingProjectsBody.BeforeImageWrapper>
                              </StylingProjectsBody.BeforeImageSection>

                              <StylingProjectsBody.ComparingIconWrapper>
                                <FontelloIcon icon="right-arrow-next" />
                              </StylingProjectsBody.ComparingIconWrapper>

                              <StylingProjectsBody.AfterImageSection>
                                <StylingProjectsBody.ComparingImageTitle>
                                  After
                                </StylingProjectsBody.ComparingImageTitle>

                                <StylingProjectsBody.AfterImageWrapper>
                                  <StylingProjectsBody.AfterImage
                                    src={`/images/after-pattern.png`}
                                    alt={"after-pattern"}
                                  />

                                  {item?.after_images?.length > 0 && (
                                    <StylingProjectsBody.AfterImageContent1
                                      image={
                                        getImage(
                                          // @ts-ignore
                                          item?.after_images[0]?.after_image
                                            ?.localFile
                                        ) as IGatsbyImageData
                                      }
                                      alt={
                                        item?.after_images[0]?.after_image
                                          ?.alt_text
                                      }
                                    />
                                  )}

                                  {item?.after_images?.length > 1 && (
                                    <StylingProjectsBody.AfterImageContent2
                                      image={
                                        getImage(
                                          // @ts-ignore
                                          item?.after_images[1]?.after_image
                                            ?.localFile
                                        ) as IGatsbyImageData
                                      }
                                      alt={
                                        item?.after_images[1]?.after_image
                                          ?.alt_text
                                      }
                                    />
                                  )}

                                  {item.after_images.length > 2 && (
                                    <StylingProjectsBody.AfterImageContent3
                                      image={
                                        getImage(
                                          // @ts-ignore
                                          item.after_images[2]?.after_image
                                            ?.localFile
                                        ) as IGatsbyImageData
                                      }
                                      alt={
                                        item?.after_images[2]?.after_image
                                          ?.alt_text
                                      }
                                    />
                                  )}
                                </StylingProjectsBody.AfterImageWrapper>
                              </StylingProjectsBody.AfterImageSection>
                            </>
                          </SwiperSlide>
                        )
                      )}
                    </Swiper>
                  </div>
                </div>
              </div>
            </StylingProjectsBody.ComparingImagesWrapper>
          )}
          <StylingProjectsBody.ChallengesWrapper>
            <StylingProjectsBody.SectionTitle>
              Challenges
            </StylingProjectsBody.SectionTitle>

            <StylingProjectsBody.SectionDivisor alwaysBlue />

            {pageContextData.acf.challenges.challenge.map((item, idx) => (
              <StylingProjectsBody.ChallengeItem key={`chi${idx}`}>
                <StylingProjectsBody.ChallengeTextsWrapper>
                  <StylingProjectsBody.ChallengeItemTitle>
                    {item?.challenge_title}
                  </StylingProjectsBody.ChallengeItemTitle>
                  <StylingProjectsBody.ChallengeItemText
                    dangerouslySetInnerHTML={{ __html: item?.challenge_text }}
                  />
                </StylingProjectsBody.ChallengeTextsWrapper>

                <StylingProjectsBody.SolutionTextsWrapper>
                  <StylingProjectsBody.ChallengeItemTitle>
                    Solution:
                  </StylingProjectsBody.ChallengeItemTitle>

                  <StylingProjectsBody.ChallengeItemText
                    dangerouslySetInnerHTML={{ __html: item?.solution_text }}
                  />
                </StylingProjectsBody.SolutionTextsWrapper>
              </StylingProjectsBody.ChallengeItem>
            ))}
          </StylingProjectsBody.ChallengesWrapper>
        </StylingProjectsBody.Container>

        {pageContextData?.acf?.website && (
          <StylingProjectsBody.VisitWebsiteButton
            href={pageContextData.acf?.website?.url}
            target="_blank"
          >
            Visit Website
          </StylingProjectsBody.VisitWebsiteButton>
        )}
      </RespContainer>

      <StylingProjectsContent.GrayBgWrapper>
        <Testimonials testimonials={pageContextData.testimonials} />
      </StylingProjectsContent.GrayBgWrapper>

      <StylingProjectsContent.GrayBgWrapper>
        <ServicesWeOffer
          services_we_offer={pageContextData.services_we_offer}
          narrowBottomMargin
        />
      </StylingProjectsContent.GrayBgWrapper>

      {/* <SectionEzDivisorNewsLetter
        section={pageContextData.globalOptions.ez_divisor_newsletter}
      /> */}

      <SectionLatestBlogPosts lastThreePost={pageContextData.lastThreePost} />
    </>
  );
};

export default Projects;
