import { Link } from "gatsby";
import styled from "styled-components";
import { AppButton, AppButtonAnchor, EzDivisor } from "../common";
import { Colors, Devices } from "../variables";
import { FontelloIcon } from "../../styles/CommonComponents";
import { GatsbyImage } from "gatsby-plugin-image";

export const ComparingIconWrapper = styled("div")`
  position: absolute;
  transform: translate(-50%, -50%) rotate(90deg);
  top: 46%;
  left: 50%;

  > span {
    font-size: 46px !important;
  }

  /* >= 992px; */
  @media all and (min-width: ${Devices.mobileBreakpoint}) {
    top: 48%;
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);

    > span {
      font-size: 70px !important;
    }
  }

  /* >= 1440px; */
  @media all and (min-width: ${Devices.laptopMacBreakpoint}) {
    > span {
      font-size: 100px !important;
    }
  }
`;

export const Container = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
  }

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
  }
`;

export const ComparingImagesWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    background: ${Colors.gray8};

    margin-top: 99px;
    padding-top: 1px;
    padding-bottom: 15px;
  }
`;

export const BeforeImageSection = styled("div")`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* padding-left: 70px; */
  }
`;

export const AfterImageSection = styled("div")`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 0;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    /* padding-left: 70px; */
  }
`;

export const ComparingImageTitle = styled("p")`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-top: 30px;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    font-size: 30px;
    margin-bottom: 30px;
  }
`;

export const BeforeImageWrapper = styled("div")`
  height: 420px;
  position: relative;
  z-index: 1;

  margin-top: -102px;
  margin-bottom: -65px;

  transform: scale(0.5);

  @media all and (min-width: 576px) {
    transform: scale(0.75);
    margin-top: unset;
    margin-bottom: unset;
  }
  @media all and (min-width: 768px) {
    transform: scale(1);
  }
`;

export const BeforeImageContent1 = styled(GatsbyImage)`
  position: absolute;
  width: 393px;
  top: 29px;
  z-index: -1;
  left: 37px;
  height: 212px;
  object-fit: fill;
  transform: perspective(119px) rotateX(1.5deg);
  clip: rect(auto, auto, 212px, auto);
`;

export const BeforeImage = styled(GatsbyImage)`
  height: 100%;
`;

export const AfterImageWrapper = styled("div")`
  height: 420px;
  position: relative;
  z-index: 1;

  margin-top: -102px;
  margin-bottom: -65px;

  transform: scale(0.5);

  @media all and (min-width: 576px) {
    transform: scale(0.75);
    margin-top: unset;
    margin-bottom: unset;
  }
  @media all and (min-width: 768px) {
    transform: scale(1);
  }
`;

export const AfterImageContent1 = styled(GatsbyImage)`
  position: absolute;
  width: 393px;
  top: 28px;
  z-index: -1;
  left: 37px;
  height: 212px;
  object-fit: fill;
  transform: perspective(119px) rotateX(1.5deg);
  clip: rect(auto, auto, 212px, auto);
`;

export const AfterImageContent2 = styled(GatsbyImage)`
  position: absolute;
  width: 142px;
  bottom: 22px;
  z-index: -1;
  left: 300px;
  object-fit: fill;
  transform: perspective(105px) rotateX(1deg);
`;

export const AfterImageContent3 = styled(GatsbyImage)`
  position: absolute;
  width: 67px;
  bottom: 24px;
  z-index: -1;
  left: 173px;
  object-fit: fill;
  transform: perspective(105px) rotateX(1deg);
  border-radius: 8px;
`;

export const AfterImage = styled(GatsbyImage)`
  height: 100%;
`;

export const ChallengesWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 35px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    align-items: center;
  }
`;

export const SectionTitle = styled("h2")`
  font-size: 24px;
  font-weight: 600;
  margin-top: 33px;
  margin-bottom: 13px;
  text-align: left;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-top: 119px;
    margin-bottom: 26px;
    text-align: center;
    font-size: 60px;
  }
`;

export const SectionDivisor = styled(EzDivisor)`
  width: 80px;
  align-self: flex-start;
  margin-bottom: 50px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    align-self: center;
    width: 120px;
    margin-bottom: 69px;
  }
`;

export const ChallengeItem = styled("div")`
  display: flex;
  background: ${Colors.gray8};
  flex-direction: column;
  padding: 20px;
  margin-bottom: 14px;
  border-radius: 8px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    padding: 33px 38px;
    margin-bottom: 30px;
    flex-direction: row;

    > div:first-child {
      padding-right: 30px;
    }

    > div:last-child {
      padding-left: 30px;
    }
  }

  > div:nth-child(2) > h3 {
    color: ${Colors.sharpBlue};
  }
`;

export const ChallengeTextsWrapper = styled("div")`
  margin-bottom: 32px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    margin-bottom: unset;
  }
`;

export const ChallengeItemTitle = styled("h3")`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 22px;

  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    font-size: 34px;
  }
`;

export const ChallengeItemText = styled("div")`
  color: ${Colors.solidGray};

  line-height: 1.5;
  font-size: 16px;
`;

export const SolutionTextsWrapper = styled("div")`
  /* >= 992px; */
  @media all and (min-width: ${Devices.bootstrapTabletBreakpoint}) {
    flex: 0 0 50%;
  }
`;

export const VisitWebsiteButton = styled(AppButtonAnchor)`
  width: 280px;
  margin-bottom: 1.5rem;

  /* >= 1200px; */
  @media all and (min-width: ${Devices.tabletLaptopBreakpoint}) {
    margin-bottom: 2.125rem;
  }
`;
